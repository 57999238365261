var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getShopAuthUser[0])?_c('v-container',{staticClass:"top-main-page"},[(_vm.getShopAuthUser[0].user_role != 'S')?[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mb-6 full-width justify-center align-center"},[_c('p',{staticClass:"section"},[_vm._v("重要な経営数値")])]),_c('Sheet',{staticClass:"mb-4",attrs:{"header":"最新の日報","count":"1","footer":{
            text: '日報一覧を見る',
            to: { name: 'drList' }
          }}},_vm._l((_vm.getFirstSectionData),function(item,index){return _c('div',{key:index},[(item.is_business_holiday == '1')?_c('BillDescription',{staticClass:"mt-3 mb-5",attrs:{"showIcon":_vm.getShopAuthUser[0].user_role == 'M' ? true : false,"color":"yellow-style","dash":"yellow-dash","button":"yellow-button","buttonLink":item.record_exist == '1'
                  ? {
                      name: 'drView',
                      params: { id: item.report_date }
                    }
                  : {
                      name: 'drInput',
                      params: { date: item.report_date }
                    },"startDate":item.report_date,"item-list":_vm.getTopReportItems(item),"icons":_vm.getDailyReportWeatherIcons(item)}}):_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"font-12px date-color"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.report_date,"YYYY-MM-DD (dd)"))+" ")]),_c('div',{staticClass:"mt-1 text-center section-font-color"},[_vm._v(" 店休日 ")])])],1)}),0)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('Sheet',{attrs:{"showButton":_vm.getShopAuthUser[0].user_role == 'M' ? true : false,"header":("月報 : " + (_vm.currentDate.format('M')) + "月の売上・コスト"),"count":"2","footer":{
            text: '月報一覧を見る',
            to: {
              name: 'monthlyReport',
              params: { date: _vm.currentDate.format('YYYY-MM') }
            }
          }}},[_c('BillDescription',{attrs:{"showIcon":_vm.getShopAuthUser[0].user_role == 'M' ? true : false,"color":"primary-style","dash":"primary-dash","button":"primary-button","buttonLink":{
              name: 'monthlyReport',
              params: { date: _vm.currentDate.format('YYYY-MM') }
            },"item-list":_vm.getTodayReportItems()}}),_c('div',{staticClass:"d-flex mb-8 flex-column justify-center align-center"},[_c('div',{staticClass:"earnings mt-12 d-flex justify-center align-center"},[_c('div',{staticClass:"text-center white--text font-16px\n              "},[_vm._v(" 売上 ")])]),_c('div',{staticClass:"font-16px mt-8 section-font-color"},[_vm._v(" "+_vm._s(_vm.currentDate.format("M"))+"月の売上データ ")])]),_c('MealCards',{attrs:{"title":"Lunch","sales-data":_vm.todayMonthlyReports ? _vm.todayMonthlyReports.lunch_sales : {},"icon":"$lunch"}}),_c('MealCards',{attrs:{"title":"Dinner","sales-data":_vm.todayMonthlyReports ? _vm.todayMonthlyReports.dinner_sales : {},"icon":"$dinner"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"mt-12 mb-6 font-16px section-font-color"},[_vm._v(" 売上の推移 ")])]),_c('MonthlySales',{attrs:{"monthlySalesData":_vm.barGraphMonthlySales ? _vm.barGraphMonthlySales.daily_reports : [],"daysInMonth":_vm.barGraphMonthlySales
                ? _vm.barGraphMonthlySales.business_days_count
                : 0,"avgSalesAmount":_vm.barGraphMonthlySales ? _vm.barGraphMonthlySales.total_sale_avg : 0}}),(_vm.getShopAuthUser[0].user_role == 'M')?_c('div',{staticClass:"d-flex mb-10 flex-column justify-center align-center"},[_c('div',{staticClass:"earnings mt-16 d-flex justify-center align-center"},[_c('div',{staticClass:"text-center white--text font-16px\n              "},[_vm._v(" コスト ")])])]):_vm._e(),(_vm.getShopAuthUser[0].user_role == 'M')?_c('DoughnutChart',_vm._b({key:_vm.dounutChartRefreshComponent,attrs:{"showLabels":true,"todayMonthlyReports":_vm.todayMonthlyReports},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"fw-500 font-16px text-79 mb-3"},[_vm._v(" "+_vm._s(_vm.currentDate.format("M"))+"月の対売上コスト構成比 ")]),(_vm.todayMonthlyReports)?_c('p',{staticClass:"font-12px fw-400 text-79 mb-8"},[_vm._v(" "+_vm._s(((_vm.todayMonthlyReports.date.month) + "月" + (_vm.todayMonthlyReports.date.from) + "日～" + (_vm.todayMonthlyReports.date.month) + "月" + (_vm.todayMonthlyReports.date.to) + "日"))+" ")]):_vm._e()]},proxy:true},{key:"inner-text",fn:function(){return [_c('div',{staticClass:"font-12px font-weight-bold"},[_vm._v("累計売上")]),_c('div',{staticClass:"font-14px font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")((_vm.todayMonthlyReports ? _vm.todayMonthlyReports.earnings : 0)))+" ")])]},proxy:true},{key:"result",fn:function(){return [_c('p',{staticClass:"mb-6 mt-6"},[_vm._v(" 累計コスト "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.todayMonthlyReports ? _vm.todayMonthlyReports.costs : 0)))])])]},proxy:true}],null,false,1440470368)},'DoughnutChart',{ labels: _vm.labels },false)):_vm._e()],1)],1)],1)]:_vm._e(),_c('v-row',[(_vm.getShopAuthUser[0].user_role == 'S')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shared-account-card",attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({
            name: 'drInput'
          })}}},[_c('div',{staticClass:"fw-700 font-18px"},[_vm._v("日報の作成")])]),_c('v-card',{staticClass:"shared-account-card mt-4",attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({ name: 'cashbookCreate' })}}},[_c('div',{staticClass:"fw-700 font-18px"},[_vm._v("現金出納/小口の作成")])]),_c('v-card',{staticClass:" shared-account-card mt-4",attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push({ name: 'accountsPayableCreate' })}}},[_c('div',{staticClass:"fw-700 font-18px mt-2"},[_vm._v("買掛/納品伝票の作成")])])],1):_vm._e(),_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12"}},[_c('HomeCardNotifications')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('HomeListLinkPosts')],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }