<template>
  <v-container class="top-main-page" v-if="getShopAuthUser[0]">
    <template v-if="getShopAuthUser[0].user_role != 'S'">
      <v-row>
        <v-col cols="12" class="py-0">
          <div class="d-flex mb-6 full-width justify-center align-center">
            <p class="section">重要な経営数値</p>
          </div>
          <Sheet
            class="mb-4"
            header="最新の日報"
            count="1"
            :footer="{
              text: '日報一覧を見る',
              to: { name: 'drList' }
            }"
          >
            <div v-for="(item, index) in getFirstSectionData" :key="index">
              <BillDescription
                :showIcon="getShopAuthUser[0].user_role == 'M' ? true : false"
                v-if="item.is_business_holiday == '1'"
                color="yellow-style"
                dash="yellow-dash"
                button="yellow-button"
                class="mt-3 mb-5"
                :buttonLink="
                  item.record_exist == '1'
                    ? {
                        name: 'drView',
                        params: { id: item.report_date }
                      }
                    : {
                        name: 'drInput',
                        params: { date: item.report_date }
                      }
                "
                :startDate="item.report_date"
                :item-list="getTopReportItems(item)"
                :icons="getDailyReportWeatherIcons(item)"
              ></BillDescription>
              <div v-else class="my-3">
                <div class="font-12px date-color">
                  {{ item.report_date | formatDate("YYYY-MM-DD (dd)") }}
                </div>
                <div class="mt-1 text-center section-font-color">
                  店休日
                </div>
              </div>
            </div>
          </Sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-0">
          <Sheet
            :showButton="getShopAuthUser[0].user_role == 'M' ? true : false"
            :header="`月報 : ${currentDate.format('M')}月の売上・コスト`"
            count="2"
            :footer="{
              text: '月報一覧を見る',
              to: {
                name: 'monthlyReport',
                params: { date: currentDate.format('YYYY-MM') }
              }
            }"
          >
            <BillDescription
              :showIcon="getShopAuthUser[0].user_role == 'M' ? true : false"
              color="primary-style"
              dash="primary-dash"
              button="primary-button"
              :buttonLink="{
                name: 'monthlyReport',
                params: { date: currentDate.format('YYYY-MM') }
              }"
              :item-list="getTodayReportItems()"
            ></BillDescription>
            <div class="d-flex mb-8 flex-column justify-center align-center">
              <div class="earnings mt-12 d-flex justify-center align-center">
                <div
                  class="text-center white--text font-16px
                "
                >
                  売上
                </div>
              </div>

              <div class="font-16px mt-8 section-font-color">
                {{ currentDate.format("M") }}月の売上データ
              </div>
            </div>
            <MealCards
              title="Lunch"
              :sales-data="
                todayMonthlyReports ? todayMonthlyReports.lunch_sales : {}
              "
              icon="$lunch"
            ></MealCards>
            <MealCards
              title="Dinner"
              :sales-data="
                todayMonthlyReports ? todayMonthlyReports.dinner_sales : {}
              "
              icon="$dinner"
            ></MealCards>
            <div class="d-flex justify-center">
              <div class="mt-12 mb-6 font-16px section-font-color">
                売上の推移
              </div>
            </div>
            <MonthlySales
              :monthlySalesData="
                barGraphMonthlySales ? barGraphMonthlySales.daily_reports : []
              "
              :daysInMonth="
                barGraphMonthlySales
                  ? barGraphMonthlySales.business_days_count
                  : 0
              "
              :avgSalesAmount="
                barGraphMonthlySales ? barGraphMonthlySales.total_sale_avg : 0
              "
            ></MonthlySales>
            <div
              v-if="getShopAuthUser[0].user_role == 'M'"
              class="d-flex mb-10 flex-column justify-center align-center"
            >
              <div class="earnings mt-16 d-flex justify-center align-center">
                <div
                  class="text-center white--text font-16px
                "
                >
                  コスト
                </div>
              </div>
            </div>
            <!-- sales cost composition ratio -->
            <DoughnutChart
              v-if="getShopAuthUser[0].user_role == 'M'"
              :showLabels="true"
              v-bind="{ labels }"
              :key="dounutChartRefreshComponent"
              :todayMonthlyReports="todayMonthlyReports"
            >
              <template v-slot:title>
                <p class="fw-500 font-16px text-79 mb-3">
                  {{ currentDate.format("M") }}月の対売上コスト構成比
                </p>
                <p
                  v-if="todayMonthlyReports"
                  class="font-12px fw-400 text-79 mb-8"
                >
                  {{
                    `${todayMonthlyReports.date.month}月${todayMonthlyReports.date.from}日～${todayMonthlyReports.date.month}月${todayMonthlyReports.date.to}日`
                  }}
                </p>
              </template>
              <template v-slot:inner-text>
                <div class="font-12px font-weight-bold">累計売上</div>
                <div class="font-14px font-weight-bold">
                  {{
                    (todayMonthlyReports ? todayMonthlyReports.earnings : 0)
                      | formatMoney
                  }}
                </div>
              </template>
              <template v-slot:result>
                <p class="mb-6 mt-6">
                  累計コスト
                  <span class="font-weight-bold">{{
                    todayMonthlyReports
                      ? todayMonthlyReports.costs
                      : 0 | formatMoney
                  }}</span>
                </p>
              </template>
            </DoughnutChart>
          </Sheet>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12" class="py-0" v-if="getShopAuthUser[0].user_role == 'S'">
        <v-card
          @click="
            $router.push({
              name: 'drInput'
            })
          "
          flat
          class="shared-account-card"
        >
          <div class="fw-700 font-18px">日報の作成</div>
        </v-card>
        <v-card
          @click="$router.push({ name: 'cashbookCreate' })"
          flat
          class="shared-account-card mt-4"
        >
          <div class="fw-700 font-18px">現金出納/小口の作成</div>
        </v-card>
        <v-card
          @click="$router.push({ name: 'accountsPayableCreate' })"
          flat
          class=" shared-account-card mt-4"
        >
          <div class="fw-700 font-18px mt-2">買掛/納品伝票の作成</div>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-10">
        <HomeCardNotifications />
      </v-col>

      <v-col cols="12">
        <HomeListLinkPosts />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Sheet from "@/components/layout/budget/Sheet";
import BillDescription from "@/components/layout/budget/BillDescription";
import MealCards from "@/components/layout/budget/MealCards";
import DoughnutChart from "@/components/layout/budget/DoughnutChart";
import MonthlySales from "@/components/layout/budget/MonthlySales";
import HomeCardNotifications from "@/components/layout/home/HomeCardNotifications";
import HomeListLinkPosts from "@/components/layout/home/HomeListLinkPosts";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  components: {
    Sheet,
    BillDescription,
    MealCards,
    DoughnutChart,
    MonthlySales,
    HomeCardNotifications,
    HomeListLinkPosts
  },
  data() {
    return {
      currentDate: dayjs(),
      dounutChartRefreshComponent: 1
    };
  },
  computed: {
    ...mapGetters([
      "getShopAuthUser",
      "getFirstShopObject",
      "getTopPageReports"
    ]),
    getFirstSectionData() {
      return this.getTopPageReports?.daily_report;
    },
    todayMonthlyReports() {
      return this.getTopPageReports?.reports;
    },
    barGraphMonthlySales() {
      return this.getTopPageReports?.bar_graph_report;
    },
    labels() {
      let profitPercent = this.todayMonthlyReports?.cost?.percentage?.profit;
      return [
        {
          color: "#FFBF1A",
          percentage:
            this.todayMonthlyReports?.cost?.percentage?.labor_cost ?? 0,
          text: "人件費",
          price: this.todayMonthlyReports?.cost?.labor_cost ?? 0
        },
        {
          color: "#14E233",
          percentage:
            this.todayMonthlyReports?.cost?.percentage?.foodstuff_cost ?? 0,
          text: "食材費 ",
          price: this.todayMonthlyReports?.cost?.foodstuff_cost ?? 0
        },
        {
          color: "#A0A4DC",
          percentage:
            this.todayMonthlyReports?.cost?.percentage?.platform_cost ?? 0,
          text: "PF手数料",
          price: this.todayMonthlyReports?.cost?.platform_cost ?? 0
        },
        {
          color: "#DCC8B1",
          percentage:
            this.todayMonthlyReports?.cost?.percentage?.rent_cost ?? 0,
          text: "家賃",
          price: this.todayMonthlyReports?.cost?.rent_cost ?? 0
        },
        {
          color: "#F4F4F4",
          percentage:
            this.todayMonthlyReports?.cost?.percentage?.other_cost ?? 0,
          text: "その他",
          price: this.todayMonthlyReports?.cost?.other_cost ?? 0
        },
        {
          color: "#FCFCBC",
          percentage: profitPercent < 0 ? -1 : profitPercent,
          text: "損益",
          price: this.todayMonthlyReports?.profit ?? 0
        }
      ];
    }
  },
  created() {
    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getTopPageDataFromApi();
    });
    this.getTopPageDataFromApi();
  },
  methods: {
    getTopPageDataFromApi() {
      this.loading = true;
      let params = {
        shop_id: this.getFirstShopObject?.id
      };
      this.$store.dispatch("GET_DAILY_REPORTS", params).then(() => {
        this.loading = false;
        this.$root.$emit("loadedTopPageData", 1);
        this.dounutChartRefreshComponent++;
      });
    },
    getTopReportItems(item) {
      return [
        {
          name: "売上",
          price: this.priceFormat(item.earning),
          description: `達成率 ${item.achievement_rate}%`
        },
        {
          name: "コスト",
          price: this.priceFormat(item.cost)
        },
        {
          name: "損益",
          price: this.priceFormat(item.profit_loss)
        }
      ];
    },
    getTodayReportItems() {
      return [
        {
          name: "売上",
          price: this.priceFormat(this.todayMonthlyReports?.earnings),
          description: `達成率 ${this.todayMonthlyReports?.achievement_rate ??
            0}%`
        },
        {
          name: "(現金売上)",
          price: this.priceFormat(this.todayMonthlyReports?.cash_sales)
        },
        {
          name: "コスト",
          price: this.priceFormat(this.todayMonthlyReports?.costs)
        },
        {
          name: "損益",
          price: this.priceFormat(this.todayMonthlyReports?.profit)
        }
      ];
    }
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
